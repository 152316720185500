import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthProvider';

export default function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthContext();

  // Show loading state or spinner while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  // Only redirect if we're sure there's no user after loading is complete
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}
