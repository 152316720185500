import { RecentScrapeRunSummary } from '../components/RecentScrapeRunSummary';
import { RawScrapeFileSummary } from '../components/RawScrapeFileSummary';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <RecentScrapeRunSummary />
      <RawScrapeFileSummary />
    </div>
  );
};

export default Dashboard;
