import { useEffect, useState } from 'react';
import { useAuthContext } from '../contexts/AuthProvider';
import { Typography, Box, CircularProgress, Alert, Paper } from '@mui/material';

interface Statistics {
  fileCount: number;
  earliestUpload: string;
  latestUpload: string;
  totalSizeBytes: number;
}

interface ApiResponse {
  success: boolean;
  statisticsOverall: Statistics;
  statisticsByStoreBrand: Record<string, Statistics>;
  isTruncated: boolean;
}

export function RawScrapeFileSummary() {
  const [stats, setStats] = useState<ApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(
          '/api/scrape/get-raw-file-stats',
          {headers: {'Authorization': `Bearer ${accessToken}`}}
        );
        const data: ApiResponse = await response.json();
        setStats(data);
      } catch (err) {
        setError('Failed to load raw scrape data statistics');
      }
    };

    fetchStats();
  }, [accessToken]);

  if (error) {
    return <Alert severity="error">Error: {error}</Alert>;
  }

  if (!stats) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  const { statisticsOverall } = stats;
  const toMB = (bytes: number) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  return (
    <Paper elevation={2} sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Raw Scrape Data Statistics
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" gutterBottom>
          Total Files: <strong>{statisticsOverall.fileCount}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total Size: <strong>{toMB(statisticsOverall.totalSizeBytes)}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Earliest Upload: <strong>{new Date(statisticsOverall.earliestUpload).toLocaleDateString()}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Latest Upload: <strong>{new Date(statisticsOverall.latestUpload).toLocaleDateString()}</strong>
        </Typography>
        {stats.isTruncated && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Note: Some files may not be included in these statistics due to pagination limits
          </Alert>
        )}
      </Box>
    </Paper>
  );
}
